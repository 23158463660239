export const SUPER_ADMIN_ROLE = "super_admin";
export const ORGANISATION_ADMIN_ROLE = "organisation_admin";
export const THERAPIST_ROLE = "therapist";
export const PATIENT_ROLE = "patient";
export const ADMIN_ROLE_LIST = [SUPER_ADMIN_ROLE, ORGANISATION_ADMIN_ROLE];
export const PROTOCOL_AUTHOR_ROLE = "protocol_author";
export const THERAPIST_AND_PATIENT_ROLE_LIST = [THERAPIST_ROLE, PATIENT_ROLE];

export type ROLE =
  | typeof SUPER_ADMIN_ROLE
  | typeof ORGANISATION_ADMIN_ROLE
  | typeof THERAPIST_ROLE
  | typeof PATIENT_ROLE
  | typeof PROTOCOL_AUTHOR_ROLE;

