<section [id]="'block-' + data.id" class="tw-block tw-text-center">

    <header *ngIf="data?.header?.length > 0" class="tw-text-left">
        {{ data?.header }}
    </header>
    <div
            *ngIf="data?.source_url?.length > 0"
            class="tw border tw-border-border tw-inline-flex tw-items-center tw-justify-center tw-rounded-md tw-overflow-clip tw-mx-auto"
    >
        <img [src]="data.source_url" class="tw-max-w-full tw-max-h-[60vh] tw-w-auto" height="auto" width="100%"/>
    </div>
</section>
