<section [id]="'block-' + data.id" class="tw-block">
    <header *ngIf="data?.header?.length > 0">
        {{ data?.header }}
    </header>
    <ng-select
            *ngIf="data?.options.length"
            [formControl]="inputFormControl"
            [items]="data?.options"
            [placeholder]="data?.config?.placeholder"
            bindLabel="label"
            bindValue="value"
            class="tw-border-red-500"
    ></ng-select>
</section>
