<ng-container *ngIf="showAlert()">
  <div
    class="tw-w-full tw-bg-yellow-100 tw-px-4 tw-py-2 tw-text-center tw-text-xs tw-font-semibold tw-drop-shadow-sm md:tw-px-6 md:tw-text-sm lg:tw-text-base"
  >
    <i class="ft-alert-circle"></i> {{ text }}
    <a class="tw-link" [routerLink]="CTA.route">
      <span class="tw-underline">{{ CTA.label }}</span>
    </a>
  </div>
</ng-container>
