<section [id]="'block-' + data.id" class="tw-block">
    <div
            *ngIf="
      data?.collapse_label &&
      data?.collapse_label !== '' &&
      isContentVisible === false
    "
            class="tw-mb-4"
    >
        <button
                (click)="isContentVisible = !isContentVisible"
                class=""
                type="button"
        >
            {{ data?.collapse_label }}
        </button>
    </div>

    <ng-container *ngIf="isContentVisible === true || !data?.collapse_label">
        <header
                *ngIf="data?.header?.length > 0"
                [class.bg-info]="data?.type != 'html'"
        >
            {{ data?.header }}
        </header>
        <div
                [innerHTML]="safeHTML"
                class="tw-font-text tw-prose tw-prose-stone tw-prose-brand md:tw-prose-lg tw-max-w-full"
        ></div>
    </ng-container>
</section>
