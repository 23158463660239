<section [id]="'block-' + data.id" class="tw-block">
    <header *ngIf="data?.header?.length > 0">
        {{ data?.header }}
    </header>
    <audio
            #audioTemplateREF
            (ended)="onAudioEnded($event)"
            (playing)="publishNewAudioPlay(data?.id)"
            class="tw-w-full"
            controls
            controlsList="nodownload"
    >
        <source [src]="sanitizedAudioURL" [type]="audioFormatString"/>
    </audio>
</section>
