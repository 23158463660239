<nav
  [ngClass]="{
    'navbar-brand-center': menuPosition === 'Top',
    'navbar-static':
      menuPosition === 'Side' && config?.layout.navbar.type === 'Static',
    'navbar-sticky':
      menuPosition === 'Side' && config?.layout.navbar.type === 'Fixed',
    'fixed-top': isSmallScreen && menuPosition === 'Top',
  }"
  class="navbar navbar-expand-lg header-navbar tw-font-text tw-flex-wrap tw-bg-white !tw-p-0"
>
  <div
    class="container-fluid navbar-wrapper tw-flex tw-max-h-[71px] tw-flex-1 tw-items-center tw-border tw-border-b !tw-p-4 md:!tw-px-6"
  >
    <div class="navbar-header d-flex">
      <div
        (click)="toggleSidebar()"
        class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center"
        data-toggle="collapse"
      >
        <i
          class="ft-menu tw-ml-2 tw-text-xl tw-font-bold tw-text-brand-main md:tw-ml-0"
        ></i>
      </div>

      <ul (clickOutside)="toggleSearchOpenClass(false)" class="navbar-nav">
        <li class="mr-2 d-none d-lg-block">
          <button
            (click)="ToggleClass()"
            appToggleFullscreen
            class="tw-hidden tw-leading-none xl:tw-inline-flex"
            type="buton"
            id="navbar-fullscreen"
          >
            <i
              class="{{
                toggleClass
              }} tw-text-brand-main tw-font-bold tw-text-xl"
            ></i>
            <span class="tw-sr-only">Toggle fullsceen</span>
          </button>
        </li>
      </ul>
    </div>
    <div class="tw-ml-5 tw-flex-1 tw-text-center">
      <div
        class="tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center"
      >
        <img
          class="tw-h-auto tw-w-full tw-object-contain"
          alt="UPLift"
          src="/assets/img/uplift-flower.webp"
        />
      </div>
    </div>
    <div>
      <div id="navbarSupportedContent" display="static" ngbDropdown>
        <button
          class="tw-transition-[colors, transform] hidden tw-relative tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-full tw-bg-brand-main tw-text-sm tw-font-semibold tw-text-white after:tw-absolute after:-tw-left-5 after:tw-text-lg after:tw-text-brand-main hover:tw-bg-brand-main/90 focus-visible:tw-bg-yellow-100 focus-visible:tw-text-foreground active:tw-scale-90"
          data-toggle="dropdown"
          type="button"
          id="dropdownBasic2"
          ngbDropdownToggle
        >
          <div>
            <span class="tw-sr-only">User Options</span>
            <ng-container
              *ngIf="(patientProfile$ | async)?.first_name; else userIcon"
            >
              {{ (patientProfile$ | async)?.first_name?.substring(0, 1)
              }}{{ (patientProfile$ | async)?.last_name?.substring(0, 1) }}
            </ng-container>
            <ng-template #userIcon>
              <i class="ft-user tw-text-lg"></i>
            </ng-template>
          </div>
        </button>
        <div
          aria-labelledby="dropdownBasic2"
          class="dropdown-menu text-left dropdown-menu-right tw-mt-3 tw-w-[260px] tw-overflow-hidden"
          ngbDropdownMenu
        >
          <div
            class="tw-font-sm tw-border-b tw-border-border tw-p-4 [&>p]:tw-overflow-hidden [&>p]:tw-text-ellipsis [&>p]:tw-whitespace-nowrap"
          >
            <p class="tw-font-bold">
              {{ (patientProfile$ | async)?.first_name }}
              {{ (patientProfile$ | async)?.last_name }}
            </p>
            <p class="tw-text-xs tw-text-muted-foreground">
              {{ (patientProfile$ | async)?.email }}
            </p>
          </div>
          <ul>
            <li>
              <a
                (click)="goToProfileEdit()"
                class="dropdown-item"
                id="profile-option"
              >
                <div class="d-flex align-items-center">
                  <i class="ft-user mr-2"></i><span>Profile</span>
                </div>
              </a>
            </li>
            <li>
              <a (click)="goToLogoutPage()" class="dropdown-item">
                <div class="d-flex align-items-center" id="logout-option">
                  <i class="ft-power mr-2"></i><span>Logout</span>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <app-alert
    *ngIf="patientProfile$ | async"
    style="display: block"
    class="tw-w-full"
    text="Please fill your mobile phone number in"
    [CTA]="{
      label: 'your profile',
      route: '/update-profile',
    }"
    [show]="
      !(patientProfile$ | async)?.telephone ||
      (patientProfile$ | async)?.telephone === ''
    "
  ></app-alert>
</nav>
