import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { RealmClientService } from "../../../../sdk/src/lib/services/realm-client.service";
import { ToastrService } from "ngx-toastr";
import { LOGIN_PATH } from "../../../../sdk/src/lib/values/shared-paths";

@Injectable({
  providedIn: "root",
})
export class NotDeactivatedUserGuard implements CanActivate {
  constructor(
    private _realmClientService: RealmClientService,
    private _router: Router,
    private _toastrService: ToastrService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const USER_CUSTOM_DATA =
      this._realmClientService?.realmClient?.currentUser?.customData;

    // Check if user is deactivated
    if (USER_CUSTOM_DATA?.is_deactivated === true) {
      return this._realmClientService.realmClient.currentUser?.logOut().then(
        () => {
          this._toastrService.error(
            "Your account has been deactivated. Please contact your organisation's administrator for further information.",
            "Account Deactivated",
          );
          void this._router.navigate(["/", LOGIN_PATH]);
          return false;
        },
        () => {
          return false;
        },
      );
    } else {
      return true;
    }
  }
}
