<section [id]="'block-' + data.id" class="tw-block">
    <header
            *ngIf="data?.header?.length > 0"
            class="!tw-mb-2 tw-bg-background tw-py-2"
    >
        {{ data?.header }}
    </header>
    <div *ngIf="data.options.length > 0">
        <form
                *ngIf="inputFormControl"
                [formGroup]="inputFormControl"
                class="tw-flex tw-flex-col tw-gap-4"
        >
            <ng-container *ngIf="checkDisplayModeTextOrImage() === true">
                <div
                        [ngClass]="{
            'md:tw-grid-cols-2': [1, '1'].includes(data.config.columns),
            'md:tw-grid-cols-2': [2, '2'].includes(data.config.columns),
            'md:tw-grid-cols-3': [3, '3'].includes(data.config.columns),
            'md:tw-grid-cols-4': [4, '4'].includes(data.config.columns),
            'md:tw-grid-cols-5': [5, '5'].includes(data.config.columns)
          }"
                        class="tw-grid tw-grid-cols-2 tw-gap-4"
                >
                    <ng-container *ngFor="let radioButton of data.options; index as i">
                        <label
                                class="tw-group tw-relative tw-flex tw-cursor-pointer tw-flex-col"
                        >
                            <input
                                #RadioButton
                                (change)="onSelectionChange(radioButton.position)"
                                [attr.name]="data.id"
                                [formControlName]="data.id"
                                [id]="data.id + radioButton?.position?.toString()"
                                [value]="radioButton.position"
                                class="tw-absolute -tw-left-[100svw]"
                                type="radio"
                            />
                            <div
                                    class="tw-relative tw-flex tw-aspect-square tw-overflow-clip tw-rounded-md tw-border tw-border-black/10"
                            >
                                <img
                                        [src]="radioButton.image_label_url"
                                        alt="{{ radioButton.label }}"
                                        class="tw-absolute tw-inset-0 tw-z-0 tw-h-full tw-max-h-full tw-w-full tw-max-w-full tw-object-cover"
                                />
                                <span
                                        class="tw-absolute tw-inset-0 tw-z-0 tw-bg-blue-600/60 tw-opacity-0 tw-backdrop-grayscale tw-transition-opacity group-has-[input:checked]:tw-opacity-100"
                                >
                </span>
                            </div>

                            <p class="tw-peer tw-w-full tw-flex-1 tw-p-2 tw-text-center">
                <span
                        [attr.for]="data?.id + data?.position?.toString()"
                        [ngbTooltip]="imageToolTipTemplateREF"
                        class="tw-inline-flex !tw-leading-none group-has-[input:checked]:tw-text-brand-main"
                >
                  <ng-container *ngIf="radioButton.label !== ''">
                    {{ radioButton.label }}
                  </ng-container>
                  <ng-container *ngIf="radioButton.label === ''">
                    {{ i + 1 }}
                  </ng-container>
                </span>
                                <span
                                        class="tw-absolute -tw-right-2 -tw-top-2 tw-z-10 tw-flex tw-size-8 tw-scale-75 tw-items-center tw-justify-center tw-rounded-full tw-bg-brand-main tw-opacity-0 tw-transition-all group-has-[input:checked]:tw-scale-100 group-has-[input:checked]:tw-opacity-100"
                                >
                  <i class="ft-check tw-font-bold tw-text-white"></i>
                </span>
                            </p>
                        </label>
                    </ng-container>
                </div>
                <div>
                    <ng-container
                            *ngIf="
              data?.config?.other_field !== undefined &&
              data?.config?.other_field?.selection_field_text
            "
                    >
                        <label
                                [attr.for]="data?.id + data?.options.length.toString()"
                                [id]="data?.id + data?.options.length.toString() + '-label'"
                        >
                            <p class="tw-radio !tw-bg-transparent">
                                <input
                                    #RadioButton
                                    (change)="onSelectionChange(data.options.length)"
                                    [attr.name]="data.id"
                                    [checked]="data.value === data.options.length"
                                    [disabled]="isInputDisabled"
                                    [formControlName]="data.id"
                                    [id]="data.id + data.options.length.toString()"
                                    [value]="data.options.length"
                                    type="radio"
                                />
                                <span
                                        *ngIf="data?.config?.other_field?.selection_field_text !== ''"
                                        class="!tw-bg-transparent"
                                >
                  {{ data?.config?.other_field?.selection_field_text }}
                </span>
                            </p>
                        </label>
                    </ng-container>

                    <ng-container *ngIf="isOtherFieldSelected(data)">
                        <fieldset
                                class="tw-form-section tw-mt-2 tw-flex tw-flex-col tw-gap-2 tw-pb-4 tw-pl-8"
                        >
                            <label
                                    [for]="data?.id + 'other-field'"
                                    [id]="data?.id + 'other-field-label'"
                            >
                                {{ data?.config?.other_field.label || "" }}
                            </label>
                            <input
                                    *ngIf="mode === 'locked'"
                                    [id]="data?.id + 'other-field-input'"
                                    [placeholder]="data?.config?.other_field.placeholder || ''"
                                    [value]="data?.config?.other_field?.body || ''"
                                    class="tw-mt-1 tw-w-full"
                                    readonly
                                    type="text"
                            />
                            <input
                                    (input)="onOtherFieldChange($event)"
                                    *ngIf="mode !== 'locked'"
                                    [formControl]="inputFormControl?.get('OTHER_FIELD')"
                                    [id]="data?.id + 'other-field'"
                                    [placeholder]="data?.config?.other_field.placeholder || ''"
                                    [readonly]="isInputDisabled"
                                    [value]="data?.config?.other_field?.body || ''"
                                    class="form-control"
                                    type="text"
                            />
                        </fieldset>
                    </ng-container>
                </div>
            </ng-container>

            <ng-container *ngIf="checkDisplayModeTextOrImage() === false">
                <div
                        [ngClass]="{
            'tw-grid-cols-2 md:tw-grid-cols-2': [2, '2'].includes(
              data.config.columns
            ),
            'tw-grid-cols-2 md:tw-grid-cols-3': [3, '3'].includes(
              data.config.columns
            ),
            'tw-grid-cols-2 md:tw-grid-cols-4': [4, '4'].includes(
              data.config.columns
            ),
            'tw-grid-cols-2 md:tw-grid-cols-5': [5, '5'].includes(
              data.config.columns
            )
          }"
                        class="tw-grid tw-overflow-clip tw-rounded-md tw-border tw-border-border"
                >
                    <ng-container *ngFor="let radioButton of data.options; index as i">
                        <div
                                class="tw-form-section tw-flex tw-flex-col [&>label]:tw-px-4 [&>label]:tw-py-3"
                        >
                            <label
                                    *ngIf="isInputDisabled"
                                    [attr.for]="data?.id + data?.position?.toString()"
                                    class="tw-radio tw-flex-1"
                            >
                                <input
                                    #RadioButton
                                    [attr.name]="data.id"
                                    [formControlName]="data.id"
                                    [id]="data.id + radioButton?.position?.toString()"
                                    [value]="radioButton.position"
                                    readonly
                                    type="radio"
                                />
                                <span
                                        [attr.for]="data?.id + data?.position?.toString()"
                                        [ngbTooltip]="imageToolTipTemplateREF"
                                >
                  {{ radioButton.label }}
                </span>
                            </label>
                            <label
                                    *ngIf="!isInputDisabled"
                                    [attr.for]="data?.id + radioButton?.position.toString()"
                                    class="tw-radio tw-flex-1 tw-px-4 tw-py-3 before:tw-absolute before:tw-inset-y-0 before:-tw-left-[1px] before:tw-z-10 before:tw-h-svh before:tw-w-[1px] before:tw-bg-border after:tw-absolute after:tw-inset-x-0 after:-tw-bottom-[1px] after:tw-z-10 after:tw-h-[1px] after:tw-w-svw after:tw-bg-border"
                            >
                                <input
                                    #RadioButton
                                    (change)="onSelectionChange(radioButton.position)"
                                    [attr.name]="data.id"
                                    [checked]="
                    radioButton.position.toString() ===
                    inputFormControl.get(data?.id).value
                  "
                                    [formControlName]="data.id"
                                    [id]="data.id + radioButton.position.toString()"
                                    [value]="radioButton.position"
                                    type="radio"
                                />
                                <span>
                  {{ radioButton.label }}
                </span>
                            </label>
                        </div>
                    </ng-container>
                    <div
                            *ngIf="
              data?.config?.other_field !== undefined &&
              data?.config?.other_field?.selection_field_text
            "
                    >
                        <label
                                [attr.for]="data?.id + data?.options.length.toString()"
                                [id]="data?.id + data?.options.length.toString() + '-label'"
                                class="tw-radio tw-px-4 tw-py-3 before:tw-absolute before:tw-inset-y-0 before:-tw-left-[1px] before:tw-z-10 before:tw-h-svh before:tw-w-[1px] before:tw-bg-border after:tw-absolute after:tw-inset-x-0 after:-tw-bottom-[1px] after:tw-z-10 after:tw-h-[1px] after:tw-w-svw after:tw-bg-border"
                        >
                            <input
                                #RadioButton
                                (change)="onSelectionChange(data.options.length)"
                                [attr.name]="data.id"
                                [checked]="data.value === data.options.length"
                                [disabled]="isInputDisabled"
                                [formControlName]="data.id"
                                [id]="data.id + data.options.length.toString()"
                                [value]="data.options.length"
                                type="radio"
                            />
                            <span
                                    *ngIf="data?.config?.other_field?.selection_field_text !== ''"
                            >
                {{ data?.config?.other_field?.selection_field_text }}
              </span>
                        </label>
                    </div>
                </div>
                <ng-container *ngIf="isOtherFieldSelected(data)">
                    <fieldset
                            class="tw-form-section tw-flex tw-flex-col tw-gap-2 tw-px-4 tw-pb-4"
                    >
                        <label
                                [for]="data?.id + 'other-field-input'"
                                [id]="data?.id + 'other-field-label'"
                                class="flex-1"
                        >
                            {{ data?.config?.other_field.label || "" }}
                        </label>
                        <input
                                *ngIf="mode === 'locked'"
                                [id]="data?.id + 'other-field-input'"
                                [placeholder]="data?.config?.other_field.placeholder || ''"
                                [value]="data?.config?.other_field?.body || ''"
                                class="form-control"
                                readonly
                                type="text"
                        />
                        <input
                                (input)="onOtherFieldChange($event)"
                                *ngIf="mode !== 'locked'"
                                [formControl]="inputFormControl?.get('OTHER_FIELD')"
                                [id]="data?.id + 'other-field-input'"
                                [placeholder]="data?.config?.other_field.placeholder || ''"
                                [readonly]="isInputDisabled"
                                [value]="data?.config?.other_field?.body || ''"
                                class="form-control"
                                type="text"
                        />
                    </fieldset>
                </ng-container>
            </ng-container>
        </form>
    </div>
</section>
