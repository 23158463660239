<section [id]="'block-' + data.id" class="tw-block">
    <header *ngIf="data?.header?.length > 0">
        {{ data?.header }}
    </header>
    <div
            class="tw-full tw-mx-0 tw-grid tw-aspect-video tw-overflow-hidden tw-rounded-md"
    >
        <video
                #videoPlayerTemplateREF
                (autoPlay)="(autoPlay)"
                (playing)="publishNewAudioPlay(data?.id)"
                *ngIf="hasVideoSourceULR; else noVideoUrlTemplate"
                [poster]="
        data?.thumbnail_image_src?.length > 0
          ? getSanitizerURL(data?.thumbnail_image_src)
          : ''
      "
                class="tw-aspect-video tw-w-full"
                controls
                crossorigin="anonymous"
        >
            <ng-container *ngFor="let source of data?.source_url_list">
                <source
                        [src]="getSanitizerURL(source?.source_url)"
                        [type]="'video/' + source?.format"
                />
            </ng-container>
            <ng-container *ngFor="let caption of data?.caption_url_list">
                <track
                        [label]="caption?.language_label"
                        [src]="getSanitizerCaptionURL(caption?.source_url)"
                        [srclang]="caption?.language_code"
                        kind="subtitles"
                />
            </ng-container>
            Your browser does not support the video tag.
        </video>
    </div>
</section>

<ng-template #noVideoUrlTemplate>
    <video
            [poster]="
      data?.thumbnail_image_src?.length > 0
        ? getSanitizerURL(data?.thumbnail_image_src)
        : ''
    "
            class="tw-aspect-video tw-w-full"
            controls
    >
        Your browser does not support the video tag.
    </video>
</ng-template>
