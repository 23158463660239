<section
  class="tw-font-text tw-flex tw-min-h-screen tw-flex-col tw-items-center tw-justify-center"
>
  <div class="tw-mx-auto tw-max-w-screen-sm">
    <div
      class="tw-rounded-md tw-border tw-border-border tw-bg-background tw-p-6"
    >
      <h1
        class="tw-font-text tw-my-6 tw-text-3xl tw-font-black tw-text-brand-main"
      >
        Oops! The page could not be found…
      </h1>
      <p class="tw-mb-4">
        The page you are looking for might have beel removed, had it's name
        changed, or is temporarily unavailable.
      </p>
      <p class="tw-text-right">
        <a [routerLink]="['/']" class="tw-button">
          <span class="tw-text-brand-main"> Back To Home </span>
        </a>
      </p>
    </div>
  </div>
  <app-footer />
</section>
