<app-navbar
  (seachTextEmpty)="checkNavbarSeachTextEmpty($event)"
  class="tw-sticky tw-top-0 tw-z-10"
/>

<div
  class="wrapper tw-flex tw-flex-1 tw-flex-col"
  [ngClass]="{ 'show-overlay': !isNavbarSeachTextEmpty }"
  (click)="onWrapperClick()"
  (window:resize)="onResize($event)"
>
  <app-horizontal-menu
    appTopMenu
    *ngIf="menuPosition === 'Top' && !displayOverlayMenu"
  ></app-horizontal-menu>

  <div
    appSidebar
    *ngIf="menuPosition === 'Side' || displayOverlayMenu"
    [ngClass]="{
      'main-menu': menuPosition === 'Side' || displayOverlayMenu,
      'menu-fixed': menuPosition === 'Side' || displayOverlayMenu,
      'menu-native-scroll': !perfectScrollbarEnable,
    }"
    class="app-sidebar tw-font-text"
    (mouseenter)="sidebarMouseenter($event)"
    (mouseleave)="sidebarMouseleave($event)"
    data-active-color="white"
    [attr.data-background-color]="
      config?.layout.variant === 'Transparent' ? 'black' : bgColor
    "
    [attr.data-image]="bgImage"
  >
    <app-sidebar></app-sidebar>
    <div
      class="sidebar-background"
      [ngStyle]="{ 'background-image': 'url(' + bgImage + ')' }"
      *ngIf="config?.layout.sidebar.backgroundImage"
    ></div>
  </div>

  <div class="main-panel tw-flex tw-flex-1 tw-flex-col">
    <div class="main-content tw-flex tw-flex-1 tw-flex-col">
      <div class="content-overlay"></div>
      <div
        class="content-wrapper mr-auto tw-flex tw-w-full tw-max-w-screen-2xl tw-flex-1 tw-flex-col"
      >
        <router-outlet></router-outlet>
      </div>
    </div>
    <app-footer></app-footer>

    <button
      class="tw-button scroll-top tw-h-12 tw-w-12 tw-shadow"
      type="button"
      *ngIf="isScrollTopVisible"
      (click)="scrollToTop()"
    >
      <span>
        <i class="ft-arrow-up"></i>
        <span class="tw-sr-only">Back to the top of the page</span>
      </span>
    </button>
  </div>

  <div
    class="sidenav-overlay"
    (click)="onOutsideClick($event)"
    [ngClass]="{
      'tw-hidden': displayOverlayMenu && hideSidebar && !overlayContent,
      'tw-block':
        displayOverlayMenu &&
        !hideSidebar &&
        overlayContent &&
        innerWidth < 1200,
    }"
  ></div>
  <div class="drag-target"></div>
</div>
