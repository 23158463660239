<section [id]="'block-' + data.id" class="tw-block">
    <header
            *ngIf="data?.header?.length > 0"
            class="tw-sticky tw-z-10 !tw-mb-2 tw-bg-background tw-py-2"
    >
        {{ data?.header }}
    </header>
    <form
            *ngIf="inputFormControl"
            [formGroup]="inputFormControl"
            class="tw-flex tw-flex-col tw-gap-4"
    >
        <ng-container *ngIf="!checkDisplayModeTextOrImageForCheckBox()">
            <div
                    [ngClass]="{
          'tw-grid-cols-2 md:tw-grid-cols-2': [2, '2'].includes(
            data.config.columns
          ),
          'tw-grid-cols-2 md:tw-grid-cols-3': [3, '3'].includes(
            data.config.columns
          ),
          'tw-grid-cols-2 md:tw-grid-cols-4': [4, '4'].includes(
            data.config.columns
          ),
          'tw-grid-cols-2 md:tw-grid-cols-5': [5, '5'].includes(
            data.config.columns
          )
        }"
                    class="tw-grid tw-overflow-clip tw-rounded-md tw-border tw-border-border"
            >
                <ng-container *ngFor="let checkBoxButton of data.options; index as i">
                    <div
                            (click)="
              !isInputDisabled ? checkBoxDivSelection(i) : this.CheckBox.click()
            "
                            class="tw-checkbox tw-cursor-pointer !tw-px-4 tw-py-3 before:tw-absolute before:tw-inset-y-0 before:-tw-left-[1px] before:tw-z-10 before:tw-h-svh before:tw-w-[1px] before:tw-bg-border after:tw-absolute after:tw-inset-x-0 after:-tw-bottom-[1px] after:tw-z-10 after:tw-h-[1px] after:tw-w-svw after:tw-bg-border has-[input:checked]:tw-bg-blue-50"
                    >
                        <input
                            #CheckBox
                            (change)="onSelectionChanged($event, checkBoxButton.position)"
                            [attr.name]="data.id + checkBoxButton.position.toString()"
                            [disabled]="isInputDisabled"
                            [formControlName]="checkBoxButton.position.toString()"
                            [id]="data.id + checkBoxButton.position.toString()"
                            [value]="checkBoxButton.position.toString()"
                            type="checkbox"
                        />
                        <label>{{ checkBoxButton.label }}</label>
                    </div>
                </ng-container>
                <label
                        *ngIf="
            data?.config?.other_field !== undefined &&
            data?.config?.other_field?.selection_field_text
          "
                        class="tw-checkbox tw-cursor-pointer !tw-px-4 tw-py-3 before:tw-absolute before:tw-inset-y-0 before:-tw-left-[1px] before:tw-z-10 before:tw-h-svh before:tw-w-[1px] before:tw-bg-border after:tw-absolute after:tw-inset-x-0 after:-tw-bottom-[1px] after:tw-z-10 after:tw-h-[1px] after:tw-w-svw after:tw-bg-border has-[input:checked]:tw-bg-blue-50"
                >
                    <input
                        #CheckBox
                        (change)="onSelectionChanged($event, data.options.length)"
                        [attr.name]="data.id + data.options.length.toString()"
                        [checked]="isSelectionChecked(data.options.length, data.value)"
                        [disabled]="isInputDisabled"
                        [formControlName]="data.options.length.toString()"
                        [id]="data.id + data.options.length.toString()"
                        [value]="data?.config?.other_field?.value || data.options.length"
                        type="checkbox"
                    />
                    <span
                            [attr.for]="data.id + data.options.length.toString()"
                            class="tw-checkbox-label"
                    >
            <ng-container
                    *ngIf="
                data?.config?.other_field?.selection_field_text === '' ||
                data?.config?.other_field?.selection_field_text !== ''
              "
            >
              {{ data?.config?.other_field?.selection_field_text }}
            </ng-container>
          </span>
                </label>
            </div>

            <ng-container *ngIf="isOtherFieldSelected(data)">
                <fieldset class="tw-form-section tw-flex tw-flex-col tw-gap-2 tw-pl-8">
                    <label [for]="data?.id + 'other-field'">
                        {{ data?.config?.other_field.label || "" }}
                    </label>
                    <input
                            *ngIf="mode === 'locked'"
                            [id]="data?.id + 'other-field-input'"
                            [placeholder]="data?.config?.other_field.placeholder || ''"
                            [value]="data?.config?.other_field?.body || ''"
                            class="tw-mt-1 tw-w-full"
                            readonly
                            type="text"
                    />
                    <input
                            (input)="onOtherFieldChange($event)"
                            *ngIf="mode !== 'locked'"
                            [formControl]="inputFormControl?.get('OTHER_FIELD')"
                            [id]="data?.id + 'other-field'"
                            [placeholder]="data?.config?.other_field.placeholder || ''"
                            [readonly]="isInputDisabled"
                            [value]="data?.config?.other_field?.body || ''"
                            class="form-control"
                            type="text"
                    />
                </fieldset>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="checkDisplayModeTextOrImageForCheckBox()">
            <div
                    [ngClass]="{
          'md:tw-grid-cols-2': [1, '1'].includes(data.config.columns),
          'md:tw-grid-cols-2': [2, '2'].includes(data.config.columns),
          'md:tw-grid-cols-3': [3, '3'].includes(data.config.columns),
          'md:tw-grid-cols-4': [4, '4'].includes(data.config.columns),
          'md:tw-grid-cols-5': [5, '5'].includes(data.config.columns)
        }"
                    class="tw-grid tw-grid-cols-2 tw-gap-4"
            >
                <ng-container *ngFor="let checkBoxButton of data.options; index as i">
                    <label
                            (click)="isInputDisabled ? checkBoxImageModeDivSelection(i) : null"
                            [ngClass]="{
              'tw-pointer-events-none': isInputDisabled
            }"
                            class="tw-group tw-relative tw-flex tw-cursor-pointer tw-flex-col"
                    >
                        <div
                                class="tw-relative tw-aspect-square tw-overflow-clip tw-rounded-md tw-border tw-border-black/10"
                        >
                            <img
                                    [src]="checkBoxButton.image_label_url"
                                    alt="{{ checkBoxButton.label }}"
                                    class="tw-absolute tw-inset-0 tw-z-0 tw-h-full tw-max-h-full tw-w-full tw-max-w-full tw-object-cover"
                            />
                            <span
                                    class="tw-absolute tw-inset-0 tw-z-0 tw-bg-blue-600/60 tw-opacity-0 tw-backdrop-grayscale tw-transition-opacity group-has-[input:checked]:tw-opacity-100"
                            ></span>
                        </div>
                        <p class="tw-p-2 tw-text-center">
                            <input
                                #CheckBoxImageMode
                                (change)="onSelectionChanged($event, checkBoxButton.position)"
                                [attr.name]="data.id + checkBoxButton.position.toString()"
                                [formControlName]="checkBoxButton.position.toString()"
                                [id]="data.id + checkBoxButton.position.toString()"
                                [value]="checkBoxButton.position.toString()"
                                class="tw-peer"
                                class="tw-absolute -tw-left-[100svw]"
                                type="checkbox"
                            />
                            <span class="group-has-[input:checked]:tw-text-brand-main">
                {{ checkBoxButton.label }}
              </span>
                        </p>
                        <span
                                class="tw-absolute -tw-right-2 -tw-top-2 tw-flex tw-size-8 tw-scale-75 tw-items-center tw-justify-center tw-rounded-md tw-bg-brand-main tw-opacity-0 tw-transition-all group-has-[input:checked]:tw-scale-100 group-has-[input:checked]:tw-opacity-100"
                        >
              <i class="ft-check tw-font-bold tw-text-white"></i>
            </span>
                    </label>
                </ng-container>
            </div>

            <div>
                <div
                        *ngIf="
            data?.config?.other_field !== undefined &&
            data?.config?.other_field?.selection_field_text
          "
                        class="tw-checkbox"
                >
                    <input
                        #CheckBox
                        (change)="onSelectionChanged($event, data.options.length)"
                        [attr.name]="data.id + data.options.length.toString()"
                        [checked]="isSelectionChecked(data.options.length, data.value)"
                        [disabled]="isInputDisabled"
                        [formControlName]="data.options.length.toString()"
                        [id]="data.id + data.options.length.toString()"
                        [value]="data.options.length"
                        type="checkbox"
                    />
                    <label
                            [attr.for]="data?.id + data?.options.length.toString()"
                            class="tw-label"
                    >
                        {{ data?.config?.other_field?.selection_field_text }}
                    </label>
                </div>
            </div>

            <ng-container *ngIf="isOtherFieldSelected(data)">
                <fieldset class="tw-form-section tw-flex tw-flex-col tw-gap-2 tw-pl-8">
                    <label [for]="data?.id + 'other-field'">
                        {{ data?.config?.other_field.label || "" }}
                    </label>
                    <input
                            *ngIf="mode === 'locked'"
                            [id]="data?.id + 'other-field'"
                            [placeholder]="data?.config?.other_field.placeholder || ''"
                            [value]="data?.config?.other_field?.body || ''"
                            class="tw-mt-1 tw-w-full"
                            readonly
                            type="text"
                    />
                    <input
                            (input)="onOtherFieldChange($event)"
                            *ngIf="mode !== 'locked'"
                            [formControl]="inputFormControl?.get('OTHER_FIELD')"
                            [id]="data?.id + 'other-field'"
                            [placeholder]="data?.config?.other_field.placeholder || ''"
                            [readonly]="isInputDisabled"
                            [value]="data?.config?.other_field?.body || ''"
                            class="tw-mt-1 tw-w-full"
                            type="text"
                    />
                </fieldset>
            </ng-container>
        </ng-container>
    </form>
</section>
