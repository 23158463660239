import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { FullLayoutComponent } from "./layouts/full/full-layout.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";

import { Full_ROUTES } from "./shared/routes/full-layout.routes";
import { CONTENT_ROUTES } from "./shared/routes/content-layout.routes";
import {
  LOGIN_PATH,
  PASSWORD_CHANGE,
  PASSWORD_RECOVERY,
  PASSWORD_RESET_CHANGE,
} from "../../../sdk/src/lib/values/shared-paths";
import { CanSeeLogInPageGuard } from "./guards/can-see-log-in-page.guard";
import { HasChangedPasswordGuard } from "./guards/has-changed-password.guard";
import { HasPatientRoleAccessGuard } from "./guards/has-patient-role-access-guard";
import { IsUserIdleTimerStopForLoginPageGuard } from "./guards/is-user-idle-start-and-stop-timer.guard";
import { ErrorPageComponent } from "./pages/content-pages/error/error-page.component";
import { NotDeactivatedUserGuard } from "./guards/not-deactivated-user.guard";

const APP_ROUTES: Routes = [
  {
    path: "",
    redirectTo: LOGIN_PATH,
    pathMatch: "full",
  },
  {
    path: LOGIN_PATH,
    canActivate: [CanSeeLogInPageGuard],
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
  },
  {
    path: PASSWORD_RECOVERY,
    loadChildren: () =>
      import("./recover-password/recover-password.module").then(
        (m) => m.RecoverPasswordModule,
      ),
  },
  {
    path: PASSWORD_CHANGE,
    loadChildren: () =>
      import("./change-password/change-password.module").then(
        (m) => m.ChangePasswordModule,
      ),
  },
  {
    path: PASSWORD_RESET_CHANGE,
    loadComponent: () =>
      import(
        "./password-reset-password-change/password-reset-password-change.component"
      ).then((m) => m.PasswordResetPasswordChangeComponent),
  },
  {
    path: "",
    component: FullLayoutComponent,
    data: { title: "full Views" },
    children: Full_ROUTES,
    canActivate: [
      HasChangedPasswordGuard,
      HasPatientRoleAccessGuard,
      IsUserIdleTimerStopForLoginPageGuard,
      NotDeactivatedUserGuard,
    ],
  },
  {
    path: "",
    component: ContentLayoutComponent,
    data: { title: "content Views" },
    children: CONTENT_ROUTES,
    canActivate: [
      HasChangedPasswordGuard,
      HasPatientRoleAccessGuard,
      IsUserIdleTimerStopForLoginPageGuard,
      NotDeactivatedUserGuard,
    ],
  },
  {
    path: "**",
    pathMatch: "full",
    component: ErrorPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(APP_ROUTES)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
