import { CommonModule } from "@angular/common";
import { Component, Input, SimpleChanges, WritableSignal, signal } from "@angular/core";
import { RouterLink } from "@angular/router";

@Component({
  standalone: true,
  selector: "app-alert",
  templateUrl: "./alert.component.html",
  imports: [CommonModule, RouterLink],
})
export class AlertComponent {
  @Input() text: string;
  @Input() CTA: {
    label: string;
    route: string;
  };
  @Input() show: boolean;
  readonly showAlert: WritableSignal<boolean> = signal(false);

  ngOnInit(): void {
    window.addEventListener("removeAlert", this._closeAlert.bind(this));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.show.currentValue !== undefined) {
      this.showAlert.set(changes.show.currentValue);
    }
  }

  ngOnDestroy(): void {
    window.removeEventListener("removeAlert", this._closeAlert.bind(this));
  }

  private _closeAlert(): void {
    this.showAlert.set(false);
  }
}
