<section [id]="'block-' + data.id" class="tw-block">
    <header *ngIf="data?.header?.length > 0">
        {{ data?.header }}
    </header>
    <ng-container *ngIf="data?.source_url?.length > 0">
        <div>
            <iframe
                    [src]="sanitizedPdfURL"
                    allowfullscreen
                    class="tw-mb-4 tw-hidden tw-h-[60dvh] tw-w-full tw-rounded-md tw-border tw-border-border md:tw-block"
            ></iframe>
        </div>
        <div class="text-right tw-hidden md:tw-block">
            <a
                    [href]="sanitizedPdfURL"
                    class="tw-link tw-invisible tw-hidden md:tw-visible md:tw-inline-flex"
                    target="_blank"
            >
                <i class="ft-file tw-mr-1"></i>
                <span>Open the PDF file</span>
            </a>
        </div>
        <div>
            <a
                    [href]="sanitizedPdfURL"
                    class="tw-button md:tw-invisible md:tw-hidden"
                    target="_blank"
            >
        <span>
          <i class="ft-file tw-mr-1"></i>
          Open the PDF file
        </span>
            </a>
        </div>
    </ng-container>
</section>
