import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { PASSWORD_CHANGE } from "../../../../sdk/src/lib/values/shared-paths";
import { RealmClientService } from "../../../../sdk/src/lib/services/realm-client.service";

@Injectable({
  providedIn: "root",
})
export class HasChangedPasswordGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _realmClientService: RealmClientService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (
      this._realmClientService.realmClient?.currentUser?.isLoggedIn === true &&
      this._realmClientService.realmClient?.currentUser?.customData
        ?.has_changed_initial_password === true
    ) {
      return true;
    } else {
      void this._router.navigate([`/${PASSWORD_CHANGE}`]);
      return false;
    }
  }
}
